<template>
    <div>
      <div
        style="
          display: flex;
          width: 100%;
          justify-content: flex-end;
          margin-block: 1rem;
          gap: 1rem;
          align-items: center;
        ">
        <el-input
          placeholder="search keyword"
          class="search_item"
          style="height: 40px !important; max-width: 20rem"
          v-model="search"
          :debounce="700"
          :disabled="isLoading"
          @input="debounceSearch"
          autocomplete="on"
          clearable
          @clear="getFraudulentMessages(1)">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-divider direction="vertical"></el-divider>
        <el-button type="primary" @click="addKeywordDialogVisible = true"
          >Add keyword</el-button
        >
      </div>
      <el-table
        :data="keywords.data || keywords"
        height="55vh"
        v-loading="isLoading">
        <el-table-column label="Keyword" prop="keyword"></el-table-column>
        <el-table-column label="Added By">
          <template slot-scope="scope">
            {{ scope.row.corporate_name || scope.row.user_name }}
          </template>
        </el-table-column>
        <el-table-column label="Created At" prop="created_at">
          <template slot-scope="scope">
            {{
              new Date(scope.row.updated_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })
            }}
          </template>
        </el-table-column>
  
        <el-table-column label="Action">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              @click="showDeleteConfirmation(scope.row.id)">
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="col-md-12" style="text-align: center; margin-top: 2em">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="per_page"
          :total="total">
        </el-pagination>
      </div>
      <!-- ! Modals -->
      <el-dialog :visible.sync="addKeywordDialogVisible">
        <div slot="title">
          <h3>Add Keyword</h3>
        </div>
        <div>
          <el-form>
            <el-form-item label="Keyword">
              <el-input
                v-model="keyword"
                placeholder="Add a new keyword"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer">
          <el-button
            @click="addKeyword"
            type="primary"
            :loading="addingKeywordLoading"
            >Add</el-button
          >
        </div>
      </el-dialog>
      <!-- ! Delete Keyword -->
      <el-dialog
        title="Delete Keyword"
        :visible.sync="deleteDialogVisible"
        width="30%">
        <span>Are you sure you want to delete this keyword?</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteDialogVisible = false">Cancel</el-button>
          <el-button
            type="danger"
            @click="deleteKeyword"
            :loading="deleteKeywordLoading"
            >Delete</el-button
          >
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
    import moment from "moment";
    export default {
      name: "reseller_keywords",
      data() {
        return {
          per_page: 50,
          total: 0,
          isLoading: false,
          keyword: "",
          deleteDialogVisible: false,
          deleteKeywordLoading: false,
          deleteKeywordId: null,
          addKeywordDialogVisible: false,
          addingKeywordLoading: false,
          search: "",
        };
      },
      computed: {
        keywords() {
          return this.$store.getters["admin/resellerFraud/keywords"] || [];
        },
      },
      methods: {
        async getKeywords(page = 1, search = "") {
          this.isLoading = true;
          try {
            const response = await this.$store.dispatch(
              "admin/resellerFraud/getKeywords",
              {
                page: page,
                search: search,
              }
            );
            this.total = response.total;
          } catch (error) {
            this.$message.error(error.response.data.message);
          } finally {
            this.isLoading = false;
          }
        },
        handleCurrentChange(val) {
          this.getKeywords(val);
        },
        async addKeyword() {
          try {
            this.addingKeywordLoading = true;
            await this.$store.dispatch("admin/resellerFraud/addKeyword", {
              keyword: this.keyword,
            });
            this.addKeywordDialogVisible = false;
            this.$message.success("Keyword added successfully");
            this.getKeywords();
          } catch (error) {
            this.$message.error(error.response.data.message);
          } finally {
            this.addingKeywordLoading = false;
          }
        },
        async showDeleteConfirmation(id) {
          this.deleteDialogVisible = true;
          this.deleteKeywordId = id;
        },
        async deleteKeyword() {
          try {
            this.deleteKeywordLoading = true;
            await this.$store.dispatch("admin/resellerFraud/deleteKeyword", {
              keyword_id: this.deleteKeywordId,
            });
            this.deleteDialogVisible = false;
            this.deleteKeywordId = null;
            this.$message.success("Keyword deleted successfully");
            this.getKeywords();
          } catch (error) {
            this.$message.error(error.response.data.message);
          } finally {
            this.deleteKeywordLoading = false;
          }
        },
        debounceSearch: _.debounce(function () {
          this.getKeywords(1, this.search);
        }, 700),
      },
      mounted() {
        // this.getKeywords();
        // ! Fetched in index.vue instead
      },
    };
  </script>
  
  <style scoped></style>